@use '../../../base.scss';

.CRMSettings {
    width: calc(100% - 230px);
    padding-left: 20px;
    border-left: solid 1px #EAEAEA;
}

.CRMSettingsSub {
    font-size: 20px;
    font-weight: 700;
    color: #020202;
    margin-bottom: 12px;
}

.CRMSettingsPar {
    font-size: 14px;
    font-weight: 500;
    color: #020202;
}

.CRMSettingsInputBox {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-items: center;
}

.CRMSettingsInput {
    width: 150px;
    outline: none;
    padding: 0px 14px;
    height: 40px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    border: solid 1px #b3b3b3;
    border-radius: 6px;
    transition: border .1s linear;

    &::placeholder {
        color: #A6A6A6;
    }

    &:focus {
        border: solid 1px #121212;
    }
}

.CRMSettingsBtn {
    margin-left: 5px;
    height: 42px;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Manrope', sans-serif;
    border-radius: 6px;
    background-color: #808080;
    cursor: pointer;
    transition: background-color .1s linear;
    pointer-events: none;

    &:hover {
        background-color: #363636;
    }
}

.CRMSettingsBtn.Active {
    background-color: #121212;
    pointer-events: auto;
}

.CRMSettingsLine {
    width: 100%;
    height: 1px;
    background-color: #fafafa;
    margin-top: 20px;
    margin-bottom: 20px;
}

.InputLoader {
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border: 1px solid #121212;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 1430px) {
    .CRMSettings {
        padding-left: 0;
        border-left: none;
        width: 100%;
    }
}

@media (max-width: 850px) {
    .CRMSettingsSub {
        font-size: 16px;
    }

    .CRMSettingsPar {
        font-size: 12px;
    }

    .CRMSettingsInput {
        font-size: 12px;
    }
}